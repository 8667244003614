






























































































































































































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import TextPreview from "@/shared/components/TextPreview.vue";
import FunnelModel, { FunnelStatus } from "@/funnels/models/FunnelModel";
import DateUtil from "@/shared/utils/DateUtil";
import { AppSection } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    TextPreview,
  },
})
export default class FunnelsView extends mixins(AppSectionAccessMixin) {
  readonly headers = [
    {
      text: this.$lang("funnel.name"),
      value: "name",
      width: "20%",
    },
    {
      text: this.$lang("funnel.description"),
      value: "description",
      width: "15%",
    },
    {
      text: this.$lang("funnel.status.title"),
      value: "status",
      width: "10%",
    },
    {
      text: this.$lang("funnel.type.title"),
      value: "type",
      width: "10%",
    },
    {
      text: this.$lang("funnel.owner"),
      value: "ownerName",
      width: "10%",
    },
    {
      text: this.$lang("accessType.title"),
      value: "accessType",
      width: "10%",
    },
    {
      text: this.$lang("funnel.lastRecalculation"),
      value: "lastSuccessfulRecalculationAt",
      width: "15%",
    },
    {
      text: this.$lang("funnel.actions.title"),
      align: "end",
      value: "action",
      width: "10%",
      sortable: false,
    },
  ];
  readonly FunnelStatus = FunnelStatus;
  deleteDialog: Record<string, any> = {
    funnel: undefined,
    value: false,
  };
  recalculateDialog: Record<string, any> = {
    funnel: undefined,
    value: false,
  };
  search = "";
  showOnlyMy = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isLoading(): boolean {
    return this.$store.state.funnelStore.loadingFunnels;
  }

  get funnels(): Array<FunnelModel> {
    return this.$store.state.funnelStore.funnels;
  }

  get currentUserFunnels(): Array<FunnelModel> {
    return this.funnels.filter(
      (it) => it.ownerId === this.currentUser.username
    );
  }

  get recalculatableStatuses(): Array<FunnelStatus> {
    return [FunnelStatus.CREATED, FunnelStatus.ACTIVE, FunnelStatus.FAILED];
  }

  get appSection(): AppSection {
    return AppSection.FUNNELS;
  }

  @Watch("applicationId", { immediate: true })
  private watchApplicationId(applicationId: string) {
    this.$store.dispatch("fetchFunnels", applicationId);
  }

  created() {
    document.title = this.$lang("documentTitle", this.$lang("funnel.title"));
  }

  isActive(status: FunnelStatus): boolean {
    return status === FunnelStatus.ACTIVE;
  }

  formatDateTime(date?: string) {
    return date ? DateUtil.formatDateTime(date) : undefined;
  }

  handleClickByTableRow(funnel: FunnelModel) {
    if (funnel.status && this.isActive(funnel.status)) {
      this.$router.push({
        name: "funnel_chart_view",
        params: {
          id: this.applicationId,
          funnelId: String(funnel.id),
        },
      });
    }
  }

  copyFunnel(funnelId: number) {
    this.$router.push({
      name: "funnel_new",
      params: {
        id: this.applicationId,
        funnelId: funnelId.toString(),
      },
    });
  }

  confirmRecalculate(funnel: FunnelModel) {
    this.recalculateDialog = {
      value: true,
      funnel,
    };
  }

  confirmDelete(funnel: FunnelModel) {
    this.deleteDialog = { value: true, funnel };
  }

  async recalculateFunnel() {
    await this.$store.dispatch(
      "recalculateFunnel",
      this.recalculateDialog.funnel
    );
    this.recalculateDialog = {
      funnel: undefined,
      value: false,
    };
  }

  async deleteFunnel() {
    await this.$store.dispatch("deleteFunnel", this.deleteDialog.funnel);
    this.deleteDialog = { funnel: undefined, value: false };
  }
}
