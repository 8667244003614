













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TextPreview extends Vue {
  @Prop({ default: "" }) value!: string;
  @Prop({ default: 20 }) maxLength!: number;
  @Prop({ default: "" }) color!: string;

  get formattedValue() {
    return this.value && this.value.length > this.maxLength
      ? `${this.value.substring(0, this.maxLength - 3)}...`
      : this.value;
  }
}
